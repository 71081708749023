<template>
  <div>
    <Header :show="true"/>
    <div class="container">
        <div class="col back cursor flexStart" @click="$router.back()">
            <img src="@/assets/images/bottom.png" alt="">
            返回
        </div>
        
        <div class="row">
            <div class="col">
                <p class="defaultWord active mb40" style="font-weight:bold">版权声明</p>
                <p class="defaultWord mb40">SIA插画博物馆数字文物库网站由SIA插画博物馆运营。</p>
                <p class="defaultWord mb40">SIA插画博物馆数字文物库网站严格遵守《中华人民共和国著作权法》、《信息网络传播权保护条例》及相关法律、法规和政策。在数字资源建设中，SIA插画博物馆积极采用先进的技术保护措施。访问、浏览或使用SIA插画博物馆数字文物库
                网站，表明您已阅读、理解并同意受以下条款的约束，并遵守所有适用的法律和法规。如果您不同意下列任何条款，请停
                止使用本网站。</p>
                <p class="defaultWord ">一、著作权声明</p>
                <p class="defaultWord mb40">SIA插画博物馆数字文物库网站中的资源，包括但不限于信息、文本、图片、链接。除特别注明外，均为SIA插画博物馆著
                作权所有。网站内容可以访问进行个人研究，但不得用于任何商业用途。未经我院书面同意，不得系统地摘录、再利用网
                站的部分内容，包括但不限于为了再利用本网站的实质性部分而利用数据挖掘、机器人或类似的数据采集和提取工具提取
                （不论一次或多次）。不得创建或发布您自己的数据库，且该数据库与本网站的实质性部分相似。如有未经许可用于商业
                用途或非法目的而侵害我院相关著作权甚至影响我院声誉的，我院保留追究其法律责任的权利。如需使用相关资料，请提
                前与我院相关部门联系。</p>
                <p class="defaultWord">二、网站内容的使用和修改</p>
                <p class="defaultWord mb40">任何单位或个人在以转载、引用、摘编、下载等方式使用本网站内容时，均须获得SIA插画博物馆的书面同意，在转载时
                须注明作者，并标明图片、文章的出处为“SIA插画博物馆”或http://www.illus-org.com/im。未经SIA插画博物馆或相关
                权利人的书面许可，不得修改所使用的内容。</p>
                <p class="defaultWord">三、侵权通知</p>
                <p class="defaultWord mb40">本网站可能有部分信息是从内容合作方、免费资源提供方转载、摘编获得的。如果您发现本网站使用了您拥有著作权的作
                品并对我们的编辑方式有异议，请向我们提供您的身份证明及您对该作品拥有著作权的有关文件，我们会尽快根据相关法
                律规定妥善处理。</p>
                <p class="defaultWord">四、不保证声明</p>
                <p class="defaultWord mb40">虽然SIA插画博物馆力图提供准确的材料和内容，但我院并不对本网站所载的材料和信息，包括但不限于文本、图片、数
                据、观点、建议或网页等材料和内容的准确性、完整性、充分性和可靠性作出保证，也不对这些材料和内容作出任何明示
                或默示的，包括但不限于有关权利、质量和没有计算机病毒的保证，并且明确声明不对这些材料和内容的错误或遗漏承担
                责任。我院也不就本网站上提供的任何产品、服务或信息做出任何声明、保证或认可。所有销售的产品和服务应受相关销
                售合同和条款的约束。</p>
                <p class="defaultWord">五、免责声明</p>
                <p class="defaultWord mb40">本网站及SIA插画博物馆不承担因您使用或不能使用本网站信息所导致的任何直接、间接或意外的损害责任，也不承担因
                您反本网站规定或触犯中华人民共和国法律而引起的任何法律责任。</p>
                <p class="defaultWord mb40">本网站如因系统维护或升级而需暂停服务时，将事先公告。若因硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站及SIA插画博物馆不承担任何责任。</p>
                <p class="defaultWord mb40">本声明之修改权、更新权和最终解释权均属SIA插画博物馆所有。</p>
            </div>
        </div>
        <div class="row">
            <div class="col flexCenter">
                <button type="button" class="btn btn-primary" @click="$router.back()">返回</button>
            </div>
        </div>
    </div>
    <Footer :footBox="2"></Footer>
  </div>
</template>
<script>
export default {
  name: "header",
  data(){
    return{
        memberLogo: [
            {
                id: 1,
                name: '泱泱文化',
                desc: '会长单位'
            }, {
                id: 2,
                name: '视觉中国',
                desc: '副会长单位'
            }, {
                id: 3,
                name: '华为主题',
                desc: '理事单位'
            }, 
        ]
    }
  }
}
</script>
<style scoped lang="scss">
.back{
    
    color: #4d4d4d;
    margin: 2rem 0;
    img{
        margin-right: 10px;
        width: 16px;
        transform: rotate(90deg);
    }
}
.bigImg{
    width: 100%;
    height: 23rem;
    overflow: hidden;
    padding: 10px;
        box-sizing: border-box;
        background: #f0f0eb;
}
.smallImg{
    height: 8rem;
    padding: 10px;
    box-sizing: border-box;
    background: #f0f0eb;
    overflow-x: scroll;
    img{
        margin-right: 10px;
        cursor: pointer;
    }
}
.mb40{
    margin-bottom: 40px!important;
}
.memHei {
    width: 100%;
    position: relative;
    min-width: 7rem;
    background: transparent;
}

.mp1 {
    font-size: 1rem;
    color: #afafaf;
}

.mp2 {
    font-size: .8rem;
    color: #afafaf;
}
/deep/.btn-primary{
    background: #fff078;
    border-color: #fff078;
    color: #4d4d4d;
}
</style>